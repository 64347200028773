import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/Image';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';

const MoviePage = () => (
    <Layout page="movie-page">
      <Seo title="MOVIE"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>MOVIE</span></h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <div className="content-block" id="movie">
            <h3 className="sub-title"><span>ムービー紹介</span></h3>
          </div>
          <div className="content-block left-end md:py-16 py-8 md:pr-20 pr-5">
            <ul className="movie-list">
            <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/Cu7uwIg7wVg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画16</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/JLjz9gKnoxY"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画15</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/LUjn5Y6nABg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画14</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/p_rYaFeh-ZA"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画13</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/vtdDFd2V6yo"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画12</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/g9lap88OsYI"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画11</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/Es0M7EaqNoE"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画10</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/WuH7t5cIlqA"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画9</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/UvY1LVhQdFs"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン紹介動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/6IMCDsxvbL0"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画8</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/HQpzBVpOmA4"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画7</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/Q3ShLEUZvTQ"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">タンドリーチキンとナンの料理風景</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/4vg0OAU-YN8"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">2BクラシックCB バッフル交換方法</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/KnhTbt7mjbg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">チェリーとプラムのオーブン焼き</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/5U2f8jA01Aw"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">ピザの焼き方</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/Qv1vXbuGfaw"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">LEDA 焚き付け</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/ncP-3KPjXNg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">ガスケット交換作業の様子</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/HbXoyXO80vY"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン 使用動画6</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/RwDK_ZkxTTg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン 使用動画5</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/eMeJkHwliC4"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン 使用動画4</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/X1Cm3Rvu48I"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">Morso 74シリーズ バーミキュライト交換方法</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/d2XmZ90yWpA"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">LEDA ALLEGRA500 バーミキュライト交換方法</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/SHnnryI5_68"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">LEDAストーブ メンテナンス</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/ZYI9FQpSbOw"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">LEDA ストーブ製作過程</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/EOhCScolrb8?start=366"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">グリル’17使用動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/DuzwCzwzXjc?start=15"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">MORSO1630CB 紹介動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/2bB8g_NKExk"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画3</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/8UoIwCeOnMA"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">［解説］MORSO 7140CB 薪の置き方と着火</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/8_qdeWLSBrk"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">［解説］LEDA ALLEGRA500 薪の置き方と着火方法</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/SMVPjUYdSqA"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">ジーコ・アウトドアオーブン使用動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/5aEZjwjUetk"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画2</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/kcrUDpOoJ98"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン イメージ動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/70idtzB3xuU"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">グリルフォーノ使用動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/y8chlpRb7fg"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">morso2800シリーズ燃焼動画</p>
              </li>
              <li>
                <div className="youtube-wrap mb-4">
                  <iframe width="560" height="315"
                          src="https://www.youtube.com/embed/TM8PVQ0CkoU"
                          title="YouTube video player" frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen />
                </div>
                <p className="text-white">アウトドアオーブン使用動画</p>
              </li>
            </ul>
          </div>
          <div className="content-block">
            <Link className="btn full white" to="/works/" id="works">
              導入事例はこちら
            </Link>
            <a className="btn pdf full mt-5" href="/update/pdf/andersen.pdf" target="_blank" id="catalog">
              PDFカタログ ダウンロード
            </a>
          </div>
        </div>
        <div className="side-list">
        <ul className="sticky">
            <li>
              <GoLink className="dot" to="movie" spy smooth duration={800}>
                ムービー紹介
              </GoLink>
            </li>
            <li>
              <GoLink className="c-yellow" to="works" spy smooth duration={800}>
                導入事例
              </GoLink>
            </li>
            <li>
              <GoLink to="catalog" spy smooth duration={800} className="pdf">
                PDFカタログ
              </GoLink>
            </li>
          </ul>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>ムービー紹介</li>
        </ul>
      </section>
    </Layout>
);

export default MoviePage;
